'use client';

import { setStage, Stage } from '@3fourteen/core';
import Bugsnag, { NotifiableError } from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React from 'react';

import { AuthProvider, ProtectRoute, useAuth } from '../contexts/auth';
import { HistoryProvider } from '../contexts/history';
import { ToastProvider } from '../contexts/toast';
import { IS_PRODUCTION } from '../services/environment';

setStage(IS_PRODUCTION ? Stage.prod : Stage.dev);

Bugsnag.start({
  appVersion: process.env.NEXT_PUBLIC_APP_VERSION,
  collectUserIp: false,
  apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY as string,
  plugins: [new BugsnagPluginReact()],
  onError: function (event) {
    if (!IS_PRODUCTION) {
      console.log('error from bugsnag :>> ', event);
      return false;
    }

    return true;
  },
});
const staleTime = 4 * 60 * 1000;

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

export default function Providers({ children }) {
  const { isAuthenticated } = useAuth();
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            retry: 1,
            // staleTime,
            enabled: isAuthenticated,
          },
        },
        queryCache: new QueryCache({
          onError: (error, query) => {
            Bugsnag.notify(error as NotifiableError, (event) => {
              event.context = 'QueryCache';
              event.addMetadata("Add'l Info", {
                query,
              });
            });
          },
        }),
      })
  );

  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <HistoryProvider>
          <AuthProvider>
            <ToastProvider>
              <ProtectRoute>{children}</ProtectRoute>
            </ToastProvider>
          </AuthProvider>
        </HistoryProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ErrorBoundary>
  );
}
